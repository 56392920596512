export const flexView = () => {
  const defalutWidth = 1920 // 默认宽度
  const defalueScale = 1 // 默认比例关系
  const html = document.querySelector('html')
  let defalutFontSize = 37.5 // 默认字体大小

  const resizeFunc = () => {
    const getWidth = window.innerWidth // 获取屏幕的宽度
    const currentScale = getWidth / defalutWidth // 计算当前的屏幕大小和默认宽度之间的比例 // 计算的宽度比例关系 再 * 默认的字体大小,获取计算的字体大小

    const currentFontSize = (currentScale / defalueScale) * defalutFontSize
    html.style.fontSize = currentFontSize + 'px'
  }
  resizeFunc()
  window.addEventListener('resize', resizeFunc)
}
