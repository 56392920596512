import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home/index.vue')
  },
  {
    path: '/About',
    name: 'about',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About/index.vue')
  },
  {
    path: '/Experiment',
    name: 'experiment',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Experiment/index.vue')
  },
  {
    path: '/Prevention',
    name: 'prevention',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Prevention/index.vue')
  },
  {
    path: '/News',
    name: 'news',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/News/index.vue')
  },
  {
    path: '/News/detailes',
    name: '/News/detailes',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/News/detailes.vue')
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
